import {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
// import type { Filter } from '@/modules/shared';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { LoadingState } from '@/modules/shared/types/state.type';
import { RootState } from '@/store/type';
import type { LighthouseIntegrationState } from '../types';

type LighthouseIntegrationContext = ActionContext<LighthouseIntegrationState, RootState>;

const state: LighthouseIntegrationState = {
  customDimensions: [],
  loadCustomDimensionsState: setStateLoadingStatusByType(),
  saveCustomDimensionsState: setStateSavingStatusByType(),
  isSyncing: false,
};

const getters: GetterTree<LighthouseIntegrationState, RootState> = {
};

const mutations: MutationTree<LighthouseIntegrationState> = {
  setCustomDimensions(state: LighthouseIntegrationState, data: LighthouseIntegrationState['customDimensions']) {
    state.customDimensions = data;
  },
  destroyCustomDimensions(state: LighthouseIntegrationState) {
    state.customDimensions = null;
  },
  setLoadCustomDimensionsState(state: LighthouseIntegrationState, loadingState: LoadingState) {
    state.loadCustomDimensionsState = setStateLoadingStatusByType(loadingState);
  },
  setSaveCustomDimensionsState(state: LighthouseIntegrationState, loadingState: LoadingState) {
    state.saveCustomDimensionsState = setStateSavingStatusByType(loadingState);
  },
  setIsSyncing(state: LighthouseIntegrationState, value: LighthouseIntegrationState['isSyncing']) {
    state.isSyncing = value;
  },
};

const actions: ActionTree<LighthouseIntegrationState, RootState> = {
  // async loadCustomDimensions({ commit }: LighthouseIntegrationContext, filter: Filter) {
  //   commit('setLoadCustomDimensionsState', { type: 'loading' });
  //   try {
  //     const response = await loadCustomDimensions(filter);
  //     commit('setCustomDimensions', response.data.data);
  //     commit('setCustomDimensionsLastPage', response.data.lastPage);
  //     commit('setLoadCustomDimensionsState', { type: 'success' });
  //   } catch (error) {
  //     commit('setLoadCustomDimensionsState', {
  //       type: 'error',
  //       error: formatErrorObject(error, 'Lighthouse Integration'),
  //     });
  //   }
  // },
  /* eslint-disable */
  async saveCustomDimensions({ commit }: LighthouseIntegrationContext, payload: any) {
    commit('setSaveCustomDimensionsState', { type: 'saving' });
    try {
      // const response = await updateCustomDimensions(payload);
      // commit('setCustomDimensions', response.data.data);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      commit('setSaveCustomDimensionsState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCustomDimensionsState', { type: 'idle' });
      }, 3000);

      /* TODO: REMOVE IS FAKE SYNCING WHEN THE API IS READY */
      commit('setIsSyncing', true);
    } catch (error) {
      commit('setSaveCustomDimensionsState', {
        type: 'error',
        error: formatErrorObject(error, 'Lighthouse Integration'),
      });
    }
  },
  destroyLighthouseIntegrationState({ commit }: LighthouseIntegrationContext) {
    commit('destroyCustomDimensions');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
